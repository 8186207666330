import { api, param2query } from "./api.service";

export const pesquisarConvenio = (query, ignorarIds) => {
  return api.get('relatorios/buscar-convenios?' + param2query({ filtrar: query, ignorarIds }));
}

export const pesquisarHospital = (query, ignorarIds) => {
  return api.get('relatorios/buscar-hospitais?' + param2query({ filtrar: query, ignorarIds }));
}

export const listarTipoInternacao = () => {
  return api.get('relatorios/listar-tipo-internacao');
}

export const listarCaraterAtendimento = () => {
  return api.get('relatorios/listar-carater-atendimento');
}

export const listarTipoFaturamento = () => {
  return api.get('relatorios/listar-tipo-faturamento');
}
